/* eslint max-len: 0 */
import React from 'react';

export const InfoCircle = (props) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      data-automation-key="info-circle"
      className="icon icon--fill-info-circle"
      {...props}
    >
      <path d="M16,8.00045623 C16,12.4185914 12.4183871,16 8,16 C3.58161287,16 0,12.4185914 0,8.00045623 C0,3.58140861 3.58161287,0 8,0 C12.4183871,0 16,3.58140861 16,8.00045623 Z M8.47919509,5.69521528 C8.90442607,5.26453379 8.89986351,4.57197605 8.46915745,4.14676932 C8.03936391,3.72065013 7.34585415,3.72521243 6.92062317,4.15589393 C6.49539219,4.58566296 6.49995476,5.27913316 6.9297483,5.70433989 C7.36045436,6.13045908 8.05396411,6.12589678 8.47919509,5.69521528 Z M9.71315159,10.4731337 L8.69387476,10.4731337 L8.69387476,8.0231765 L8.69387476,7.19101226 C8.69387476,7.11436555 8.66284932,7.04501853 8.61266112,6.99483319 C8.5615604,6.94464785 8.49220942,6.91362418 8.41555834,6.91362418 L7.58425915,6.91362418 L6.62794571,6.91362418 C6.47464355,6.91362418 6.34871678,7.03771885 6.34871678,7.19101226 L6.34871678,8.0231765 C6.34871678,8.17646992 6.47464355,8.30147705 6.62794571,8.30147705 L7.30503023,8.30147705 L7.30503023,10.4731337 L6.28666591,10.4731337 C6.13336375,10.4731337 6.00743698,10.5990533 6.00743698,10.7523467 L6.00743698,11.584511 C6.00743698,11.7368919 6.13336375,11.8618991 6.28666591,11.8618991 L7.58425915,11.8618991 L8.41555834,11.8618991 L9.71315159,11.8618991 C9.86645375,11.8618991 9.99146801,11.7368919 9.99146801,11.584511 L9.99146801,10.7523467 C9.99146801,10.5990533 9.86645375,10.4731337 9.71315159,10.4731337 Z" />
    </svg>
  );
};
